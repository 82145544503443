/**
 * @author Robert SKIUBIJ <robert.skubij@dat.eu>
 *
 * Simple function returning Date Time with timezone.
 *
 * @type {{now: (function(): {date: string, timezone: *, locale: string | string})}}
 */
const DateTimeZone = {
    now: () => {

        const locale = navigator.languages !== undefined ? navigator.languages[0] : "en-US";
        const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

        return {
            date: new Date().toLocaleString(locale, { timeZone: timezone }),
            timezone: timezone,
            locale: locale
        };
    }
}

const dateYYYYMM = date =>{
    const year = date.getYear();
    const month = date.getMonth()+1;
    const day = date.getDay();

}
const toISOStringWithTimezone = date => {
    const tzOffset = -date.getTimezoneOffset();
    const diff = tzOffset >= 0 ? '+' : '-';
    const pad = n => `${Math.floor(Math.abs(n))}`.padStart(2, '0');
    return date.getFullYear() +
        '-' + pad(date.getMonth() + 1) +
        '-' + pad(date.getDate()) +
        'T' + pad(date.getHours()) +
        ':' + pad(date.getMinutes()) +
        ':' + pad(date.getSeconds()) +
        diff + pad(tzOffset / 60) +
        ':' + pad(tzOffset % 60);
};

export {
    DateTimeZone,
    toISOStringWithTimezone
}
