import { css, html, LitElement, noChange, nothing } from "lit";
import '@vaadin/accordion';
import '@vaadin/checkbox';
import '@vaadin/accordion';
import '@vaadin/icon';
import '@vaadin/icons'

class LicenceAgreement extends LitElement {

    static styles = css
        `
          .agreement {
            margin-top: 1.2rem;
          }

          .accordo {
            display: flex;
            flex-flow: column;
          }

          .terms-details {
            white-space: pre-line;
          }

          .company-name {
            font-weight: bold;
          }

          .contact {
            font-family: "Courier New";
            font-size: 0.9rem;
            margin-top: 0.3em;
          }

          .contact::after {

          }

          .address {
            white-space: pre-line;
            color: #8f8f8f;
          }


        `;
    static properties = {
        text: {},
        checkBoxEnabled: { type: Boolean },
        value: { type: Boolean },
        terms: {}
    }

    _resolve(e) {

        if (e !== undefined)
            this.dispatchEvent(
                new CustomEvent("licence-agreement", {
                    detail: e.detail.value,
                    bubbles: true,
                    composed: true,
                })
            );
    }

    constructor() {
        super();
        this.checkBoxEnabled = false;
    }

    render() {

        const agreement_expand = html`
            <div class="agreement">
                <vaadin-accordion opened="false">
                    <vaadin-accordion-panel>
                        <div slot="summary">${this?.terms?.title}</div>

                        <vaadin-vertical-layout>
                            <address class="address">
                                <span class="company-name"> ${this?.terms?.company}</span>
                                <div class="">
                                    ${this?.terms?.address}
                                </div>
                            </address>

                            ${this.terms?.contact.mail ? html`
                                <span class="contact">@: <a
                                        href="mailto:${this?.terms?.contact?.mail}">${this?.terms?.contact?.mail}</a>
                                </span>` : ""}
                            
                            ${this.terms?.contact.phone ? html`
                                <span class="contact">T: <a
                                    href="call:${this?.terms?.contact?.phone}">${this?.terms?.contact?.phone}</a>
                                </span>` : ""}

                            ${this.terms?.termdetails ? html`
                                <div class="terms-details">
                                    ${this?.terms?.termdetails}
                                </div>
                                <div>
                                    <vaadin-button @click="${() => window.scrollTo(0, 0)}">
                                        <vaadin-icon icon="vaadin:arrow-up"></vaadin-icon>
                                    </vaadin-button>
                                </div>
                            ` : ""}
                        </vaadin-vertical-layout>
                    </vaadin-accordion-panel>
                </vaadin-accordion>
            </div>
        `;

        return html
            `
                <div class="accordo">
                    <vaadin-checkbox label="${this.text}" @checked-changed="${this._resolve}"
                                     .checked="${this.value}"></vaadin-checkbox>
                    ${this?.terms ? agreement_expand : ""}
                </div>

            `;
    }

}

window.customElements.define('licence-agreement', LicenceAgreement)
