import { css, html }                             from "lit";
import WeView                                    from "../../component/basecomponent/WeView";
import { getCurrentGeolocation }                 from "../../lib/GeoPermissions";
import './components/LicenceAgreement';

import '@vaadin/button';
import { DateTimeZone, toISOStringWithTimezone } from "../../lib/DateTimeZone";

class WelcomePageComponent extends WeView {
    static styles = css`

      .hero-with-logo {
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        justify-content: space-between;
      }
    `;

    static properties = {
        i18n: { type: Object },
        config: { type: Object },
        permissions: { type: Boolean },
        cameraPermissions: { type: Boolean },
        geoPermissions: { type: Boolean },
        licencePermissions: { type: Boolean }
    };

    constructor () {
        super();
        this.permissions = true;

        this.cameraPermissions = true;
        this.geoPermissions = true;
        this.askPermissions = true;
        this.licencePermissions = true;


        this.i18n = {
            companyName: "",
            heroTitle: "",
            heroSubTitle: "",
            title: "Notre centre d'assistance",
            subTitle: "",
            mail: "",
            call: "",
            toBeContinue: "",
            logo: "",
            agreementText: "",
            btnNextText: "",
            licenceText: "",
            linkToFullTextOfAgreement: "",
            footer: "",
            backgroundImage: "",
            linkToWebPage: "",
            btnPermissionsText: "",
            permissionsText: "",
        };


    }

    resolve () {

        this.dispatchEvent(
            new CustomEvent("welcome-resolved", {
                detail: toISOStringWithTimezone(new Date()),
                bubbles: true,
                composed: true,
            })
        );
    }

    firstUpdated ( _changedProperties ) {

        super.firstUpdated(_changedProperties);

        this.licencePermissions = this.config.askLicenceAgreement === undefined;

        if (this.config.askPermissions === true) {
            this._cameraPermissions();
            this._checkGeoPermissions();
            this._checkIfPermissionsGranted();
        }

    }

    _validate = _ => this.cameraPermissions && this.geoPermissions && this.licencePermissions;


    _setCameraPermissions () {
        return navigator.mediaDevices.getUserMedia({ video: true });
    }

    _checkGeoPermissions () {
        navigator?.permissions?.query({ name: "geolocation" }).then(( result ) => {
            if (result.state === "denied") this.geoPermissions = false;
            else this.geoPermissions = true;
        });
    }

    _cameraPermissions ( e ) {
        if (navigator.mediaDevices && navigator.mediaDevices.enumerateDevices) {
            // Firefox 38+ seems having support of enumerateDevicesx
            navigator.enumerateDevices = function ( callback ) {
                navigator.mediaDevices.enumerateDevices().then(callback);
            };
        }

        var MediaDevices = [];
        var isHTTPs = location.protocol === "https:";
        var canEnumerate = false;

        if (
            typeof MediaStreamTrack !== "undefined" &&
            "getSources" in MediaStreamTrack
        ) {
            canEnumerate = true;
        } else if (
            navigator.mediaDevices &&
            !!navigator.mediaDevices.enumerateDevices
        ) {
            canEnumerate = true;
        }

        var hasMicrophone = false;
        var hasSpeakers = false;
        var hasWebcam = false;

        var isMicrophoneAlreadyCaptured = false;
        var isWebcamAlreadyCaptured = false;

        function checkDeviceSupport ( callback ) {
            if (!canEnumerate) {
                return;
            }

            if (
                !navigator.enumerateDevices &&
                window.MediaStreamTrack &&
                window.MediaStreamTrack.getSources
            ) {
                navigator.enumerateDevices = window.MediaStreamTrack.getSources.bind(
                    window.MediaStreamTrack
                );
            }

            if (!navigator.enumerateDevices && navigator.enumerateDevices) {
                navigator.enumerateDevices = navigator.enumerateDevices.bind(navigator);
            }

            if (!navigator.enumerateDevices) {
                if (callback) {
                    callback();
                }
                return;
            }

            MediaDevices = [];
            navigator.enumerateDevices(function ( devices ) {
                devices.forEach(function ( _device ) {
                    var device = {};
                    for (var d in _device) {
                        device[d] = _device[d];
                    }

                    if (device.kind === "audio") {
                        device.kind = "audioinput";
                    }

                    if (device.kind === "video") {
                        device.kind = "videoinput";
                    }

                    var skip;
                    MediaDevices.forEach(function ( d ) {
                        if (d.id === device.id && d.kind === device.kind) {
                            skip = true;
                        }
                    });

                    if (skip) {
                        return;
                    }

                    if (!device.deviceId) {
                        device.deviceId = device.id;
                    }

                    if (!device.id) {
                        device.id = device.deviceId;
                    }

                    if (!device.label) {
                        device.label = "Please invoke getUserMedia once.";
                        if (!isHTTPs) {
                            device.label =
                                "HTTPs is required to get label of this " +
                                device.kind +
                                " device.";
                        }
                    } else {
                        if (device.kind === "videoinput" && !isWebcamAlreadyCaptured) {
                            isWebcamAlreadyCaptured = true;
                        }

                        if (device.kind === "audioinput" && !isMicrophoneAlreadyCaptured) {
                            isMicrophoneAlreadyCaptured = true;
                        }
                    }

                    if (device.kind === "audioinput") {
                        hasMicrophone = true;
                    }

                    if (device.kind === "audiooutput") {
                        hasSpeakers = true;
                    }

                    if (device.kind === "videoinput") {
                        hasWebcam = true;
                    }

                    MediaDevices.push(device);
                });

                if (callback) {
                    callback();
                }
            });
        }

        checkDeviceSupport(() => {
            this.cameraPermissions = isWebcamAlreadyCaptured === true;
        });
    }

    _checkIfPermissionsGranted () {
        return this.cameraPermissions && this.geoPermissions;
    }

    _allowPermissions () {
        const geo = getCurrentGeolocation();
        const camera = this._setCameraPermissions();

        Promise.all([geo, camera]).then(( values ) => {
            if (values[0].latitude && values[1].active) {
                this.permissions = true;
                this.geoPermissions = true;
                this.cameraPermissions = true;
            }
        });
    }

    _tokenizeText ( text ) {

    }

    render () {

        const mailSection = html`
            <p>
                <span class="icon-text">
                    <span class="icon">
                        <i class="fa-solid fa-at"></i>
                    </span>
                    <span>
                        <a aria-label="mail to" href="mailto:${this.i18n.mail}"
                           class="white-space-pre-line">${this.i18n.mail}</a>
                    </span>
                </span>
            </p>`;

        const callSection = html`
            <p>
                <span class="icon-text">
                    <span class="icon">
                        <i class="fa-solid fa-phone"></i>
                    </span>
                    <span>
                        <a aria-label="Call to" href="tel:${this.i18n.call}"
                           class="white-space-pre-line">${this.i18n.call}</a>
                    </span>
                </span>
            </p>`;

        const licenceSection = html`
            <div class="licence-text">
                <a aria-label="Licence"
                   href="${this.i18n?.linkToFullTextOfAgreement?.ref}"
                   class="white-space-pre-line">${this.i18n?.linkToFullTextOfAgreement?.label}</a>
            </div>
        `;

        const displayLogo = html`
            <div class="logo-image">
                <img src="${this.config.logo}" alt="logo">
            </div>
        `;

        /**
         * Show content when param 'waveVisible' ans 'topVisible' is true
         *
         * @type {TemplateResult<1>}
         */
        const topWave = html`
            <section class="hero is-white is-small">
                <div class="hero-body ${!this.config.waveVisible === true ? 'hero-body-is-white' : ''}">
                    <div class=" ${this.config.logo && this.config.logoVisible ? "hero-with-logo":"hero-title"}">
                        <div class="hero-title">
                            <p class="title white-space-pre-line">
                                ${this.i18n.heroTitle}
                            </p>
                            <p class="subtitle  white-space-pre-line">
                                ${this.i18n.heroSubTitle}
                            </p>
                        </div>
                            ${this.config.logo && this.config.logoVisible ? displayLogo:""}
                    </div>
                </div>

                <svg class="${!this.config.waveVisible === true ? 'wave-is-hidden' : ''}"
                     viewBox="0 0 786 71"
                     id="svg4"
                     xmlns="http://www.w3.org/2000/svg">
                    <path
                            fill="#f3f4f5"
                            fill-opacity="1"
                            d="M 786.45168,25.763344 742.75992,35.773477 C 699.06816,45.890956 611.68463,65.750204 524.30112,70.124601 436.91759,74.33798 349.53407,63.066519 262.15054,50.104337 174.76702,37.303175 87.3835,22.811294 43.69174,15.753211 L 0,8.587781 V 0 h 43.69174 c 43.69176,0 131.07528,0 218.4588,0 87.38353,0 174.76705,0 262.15058,0 87.38351,0 174.76704,0 218.4588,0 h 43.69176 z"
                            id="path2"
                            style="stroke:none;stroke-width:0;stroke-miterlimit:4;stroke-dasharray:none"/>
                </svg>

            </section>
        `;

        /**
         * Show content when param 'askPermissions' is true.
         *
         * @type {TemplateResult<1>}
         */
        const grantPermissions = html`
            <div class="rounded-box we-block allow-permissions-text"
                 style="${this.cameraPermissions && this.geoPermissions ? "display: none;" : ""}">


                ${this.i18n.permissionsText}
                <div class="we-block-action">
                    ${!(this.cameraPermissions && this.geoPermissions)
                            ? html`
                                <vaadin-button theme="primary error"
                                               class=" is-fullwidth"
                                               @click=${() => this._allowPermissions()}
                                               class="card-footer-item"
                                >
                                    ${this.i18n.btnPermissionsText}
                                </vaadin-button>`
                            : ""
                    }
                </div>
            </div>

        `;

        const licenceAgreement = html`
            <div class="rounded-box we-block licence-agreement">

                <licence-agreement text="${this.config?.askLicenceAgreement?.text}"
                                   .terms="${this.config?.askLicenceAgreement?.terms}"
                                   .value="${this.licencePermissions}"
                                   @licence-agreement="${e => this.licencePermissions = e.detail}">
                </licence-agreement>
            </div>
        `;


        return html`
            <style>

                @media screen and (max-width: 460px) {
                    .card-box {
                        padding-top: 1em;
                        display: flex;
                        flex-direction: column;
                        height: 100%;
                    }

                    .card-content {
                        margin-bottom: auto;
                    }

                    .on-mobile-fullwidth {
                        width: 100%;
                    }
                }

                @media screen and (min-width: 640px) {
                    .container {
                        max-width: 800px;
                    }

                    .card-box {
                        max-width: 640px;
                        justify-content: center;
                        align-items: center;
                        margin: auto;
                        padding-top: 5em;
                    }

                    .card-action {
                        margin-top: auto;
                        display: flex;
                        flex-direction: row;
                        justify-content: end;
                    }

                    .spacer {
                        width: 100%;;
                    }
                }

                #svg4 {
                    width: 100%;
                    background-color: white;
                }

                .wave-is-hidden {
                    display: none;
                }

                .button-is-native {
                    color: white;
                    background-color: #465064;
                    border-radius: 0px;
                    height: 2.8rem;
                }

                .button-is-permissions {
                    color: white;
                    background-color: #8c3737;
                    border-radius: 0px;
                    height: 2.8rem;
                }

                .hero-body {
                    background-color: #f3f4f5;
                }

                .hero-body-is-white {
                    background-color: white;
                }

                .hero-with-logo {
                    display: flex;
                    flex-direction: row;
                    flex-wrap: nowrap;
                    justify-content: space-between;
                    align-items: flex-start;
                }

                .hero-title-with-logo {
                    max-width: 50%;
                }

                .hero-title {
                    max-width: 100%;
                }

                .hero-title-gradient {
                    background: #A7CFDF;
                    background: -webkit-linear-gradient(to bottom left, #A7CFDF 0%, #23538A 100%);
                    background: -moz-linear-gradient(to bottom left, #A7CFDF 0%, #23538A 100%);
                    background: linear-gradient(to bottom left, #A7CFDF 0%, #23538A 100%);
                    -webkit-background-clip: text;
                    -webkit-text-fill-color: transparent;


                }

                .logo-image {
                    display: flex;
                    max-width: 40%;
                    max-height: 3.5rem;
                }

                .logo-image > img {
                    display: block;

                }

                .licence-text {
                    margin-top: 1.8em;
                }

                .allow-permissions-text {
                    margin-top: 1.2em;
                    color: #2f2727;
                }

                .button[disabled], fieldset[disabled] .button {
                    background-color: #ffffff;
                    color: #a6a6a6;
                    border-color: #dbdbdb;
                    box-shadow: none;
                    opacity: 0.5;
                }

                .rounded-box {
                    border: solid 1px #d3d3d3;
                    border-radius: 5px;
                }

                .we-block {
                    padding: 0.5rem;
                }

                .we-block-action {
                    width: 100%;
                    margin-top: 1em;
                }

                .contact > p:not(:last-child) {
                    margin-bottom: 0.5rem;
                }


                .sub-content {
                    margin-top: 1.5rem;
                }

                .intro-text {
                    font-size: 1.2rem;
                }


                .card-content > .content {
                    margin-top: 1.5em;

                }

                .card-image {
                    padding: 1.5em;
                }

                .card-action {
                    margin-top: auto;
                    padding: 1.5em 1.5em 0.5em;
                }

                .container {
                    height: 100%;
                    display: flex;
                    flex-direction: column;
                    background-color: white;
                }

                .licence-agreement {
                    margin-top: 1.8em;

                }

            </style>
            <div class="container">

                ${this.config?.topVisible === true ? topWave : ""}

                <div class="card-box">
                    <div class="card-content card-stripe ">
                        <div class="content">
                            <p class="title is-5 is-4-desktop white-space-pre-line">${this.i18n.title}</p>
                            <p class="subtitle is-6 white-space-pre-line">${this.i18n.subTitle}</p>
                            <div class="sub-content">
                                <p class="intro-text white-space-pre-line">
                                    ${this.i18n.toBeContinue}
                                </p>
                                ${this.i18n?.mail?.length > 0 ? mailSection : ""}
                                ${this.i18n?.call?.length > 0 ? callSection : ""}
                                ${this.i18n?.linkToFullTextOfAgreement ? licenceSection : ""}
                            </div>

                            ${this.config.askPermissions === true ? grantPermissions : ""}

                            ${this.config.askLicenceAgreement !== undefined ? licenceAgreement : ""}

                        </div>
                    </div>
                    
                    <div class="card-action">

                        <vaadin-button .disabled=${!this._validate()}
                                       theme="primary large"
                                       @click=${this.resolve} class="card-footer-item">
                            ${this.i18n.btnNextText}
                        </vaadin-button>
                    </div>
                </div>

            </div>
        `;
    }
}

window.customElements.define("welcome-page", WelcomePageComponent);
